'use client'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Slider, Button } from '@/components/ui'
import { tallyForm } from '@/config/site'

import { useState } from 'react'

interface IBaseSlider {
  label: string
  value: string
  setValue: CallableFunction
  max: number
  step: number
  prefix?: string
  postfix?: string
}

function BaseSlider(props: IBaseSlider) {
  const { label, value, setValue, max, step, prefix, postfix } = props
  return (
    <div className='flex w-full flex-col gap-y-2'>
      <div className='flex items-center justify-between'>
        <label className='text-bg-white text-sm' htmlFor={label}>
          {label}
        </label>
        <p className='text-bg-white font-bold'>
          {prefix}
          {value}
          {postfix}
        </p>
      </div>
      <Slider
        onValueCommit={(value) => setValue(value)}
        name={label}
        defaultValue={[value as unknown as number]}
        max={max}
        step={step}
      />
    </div>
  )
}

interface ISelector {
  label: string
  placeholder: string
  values: string[]
  onChange?: CallableFunction
}

function Selector(props: ISelector) {
  const { label: label, placeholder, values, onChange } = props
  return (
    <div className='flex flex-col gap-y-1 text-left'>
      <label htmlFor='API TYPE' className='text-left text-sm font-[500]'>
        {label}
      </label>
      <Select onValueChange={(val) => onChange && onChange(val)} name={label} defaultValue={values[0]}>
        <SelectTrigger className='border-[#767CAF99] border-opacity-60'>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className='border-none'>
          {values?.map((val, index) => {
            return (
              <SelectItem value={val} key={index}>
                {val.charAt(0).toUpperCase() + val.slice(1)}
              </SelectItem>
            )
          })}
        </SelectContent>
      </Select>
    </div>
  )
}

const hostingOptions = ['Hosted by you', 'Hosted by Tiny API']

export function CalculateIncome() {
  const [price, setPrice] = useState(24)
  const [users, setUsers] = useState(100)
  const [currentHostingOption, setCurrentHostingOption] = useState<string | null>(hostingOptions[0])

  const onHostinOptionChange = (val: string) => {
    setCurrentHostingOption(val)
  }

  return (
    <section className='flex flex-col gap-y-8 py-14 text-center'>
      <div className='flex w-full flex-col gap-y-3'>
        <h2 className='text-[30px] font-[600] lg:text-[44px]'>Calculate Your Potential Earnings with Tiny API</h2>
        <p className='mx-auto max-w-4xl text-center text-lg md:text-2xl'>
          Discover how much you could make by listing or building your APIs with us. Customize the parameters below to
          see your estimated earnings.
        </p>
      </div>
      <div className='mx-auto flex w-full max-w-3xl flex-col gap-8 py-4'>
        <div className='grid gap-4 md:grid-cols-2'>
          <Selector
            label='Choose Your API Hosting Option'
            placeholder='Hosted by You'
            values={hostingOptions}
            onChange={onHostinOptionChange}
          />
          <Selector
            label='Select Your Pricing Model'
            placeholder='Tiered-Subscription'
            values={['Tiered-Subscription', 'Pay-As-You-Go']}
          />
        </div>
        <div className='flex flex-col gap-y-5 text-left'>
          <BaseSlider
            value={price as unknown as string}
            setValue={setPrice}
            step={2}
            max={200}
            prefix='$'
            postfix='/M'
            label='Average Subscription Price (Above free tier)'
          />
          <BaseSlider
            value={users as unknown as string}
            setValue={setUsers}
            step={10}
            max={10000}
            label='Estimated Monthly Users'
          />
        </div>
      </div>
      <div className='flex w-full flex-col items-center gap-y-5 py-4'>
        <p className='flex items-end gap-2 font-bold'>
          <span className='text-3xl text-foreground'>
            ${(price * users).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>{' '}
          <span className='text-lg text-muted-foreground/80'>Per Month</span>
        </p>
        <a href={tallyForm} target='_blank' rel='noreferrer'>
          <Button variant={'secondary'} className='px-6 py-3 text-xl'>
            Start Earning with Tiny API
          </Button>
        </a>

        <p className='text-xs text-[#FFFFFF99]'>
          Developers keep {currentHostingOption === hostingOptions[0] ? `90%` : `75%`} of their revenue minus credit
          card fees.
        </p>
      </div>{' '}
    </section>
  )
}
