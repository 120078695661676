import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/hero.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/showcase.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(main)/(marketing)/_components/section4.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(main)/(marketing)/_components/section6.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/avatar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/command.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/dropdownMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/select.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/slider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/toast.tsx")