'use client'

import { useLottie } from 'lottie-react/'
import devanimation from '../../../../../public/footerimage.json'
import Link from 'next/link'
import { Button, GradientButton } from '@/components/ui'

export function FooterCTA() {
  const options = {
    animationData: devanimation,
    loop: true,
  }

  const { View } = useLottie(options)

  return (
    <section className='w-full grid-cols-3 items-center gap-x-5 py-28 text-center md:grid'>
      <div className='col-span-2 flex w-full flex-col gap-y-10 '>
        <h2 className='text-[30px] font-[600] md:text-[52px]'>Exploring what to build?</h2>
        <p className='text-2xl'>
          We built a list of APIs we believe are powerful for companies would consider worth paying for.
        </p>
        <div className='flex w-full flex-col items-center justify-center gap-5 md:flex-row'>
          <Link href={'/resources/request-for-apis'}>
            <Button variant={'secondary'} className='h-11 w-full shrink-0 px-6 py-0 text-sm'>
              See API List
            </Button>
          </Link>
          <a
            href='https://join.slack.com/t/tinyapi/shared_invite/zt-20rzmvply-KkDU2itMk9_sdIT4jpS3Dg'
            target='_blank'
            rel='noreferrer'
          >
            <GradientButton>Meet Other Developers</GradientButton>
          </a>
        </div>
      </div>
      <div>{View}</div>
    </section>
  )
}
